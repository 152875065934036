@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;



html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
}


.cabin {
    font-family: "Cabin", sans-serif;
}

.nav {
    height: calc(100% - 66px);
}

.cabin-600 {
    font-weight: 400;
}

.cabin-600 {
    font-weight: 500;
}

.cabin-600 {
    font-weight: 600;
}

.cabin-700 {
    font-weight: 700;
}

.navLogoPanda {
    width: 30px;
}

.polygon2 {
    /* box-sizing: border-box;

  position: absolute;
  width: 16.64px;
  height: 16.64px;
  right: 24px;
  top: 196.73px;

  border: 1.46813px solid #252525;
  transform: rotate(110.14deg); */
}

.polygon3 {
    /* box-sizing: border-box;
  position: absolute;
  width: 31.82px;
  height: 31.82px;
  left: 38px;
  top: 442.06px;
  transform: rotate(-77.5deg); */
}

.polygon4 {
    /* box-sizing: border-box;
  position: absolute;
  width: 30.54px;
  height: 30.54px;
  right: 0;
  top: 426.69px; */
}

.polygon5 {
    /* box-sizing: border-box;
  position: absolute;
  width: 30.54px;
  height: 30.54px;
  left: 0;
  top: 426.69px; */
}

.polygon6 {
    /* box-sizing: border-box;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 152px;
  top: 582.75px;
  transform: rotate(-120.97deg); */
}

.mobilefooterCircle {
    clip-path: ellipse(90% 90% at bottom center);
}

.desktopfooterCircle {
    clip-path: ellipse(50vw 100% at bottom center);
}

.form {
    left: 50%;
    transform: translateX(-50%);
}

.email_input {
    background-image: url("../public/logos/user1.png");
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
}

.password_input {
    background-image: url("../public/logos/lock1.png");
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
}

.services {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.services:hover {
    box-shadow: 0px 4.32172px 4.32172px rgba(0, 0, 0, 0.25),
        inset 0px 4.32172px 4.32172px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(69.1475px);
}

.headline {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        inset 0px 2.16086px 4.32172px rgba(0, 0, 0, 0.25);
}

.playground-dummy__button {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.grid-1,
.grid-2 {
    box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.25);
}

.RichEditor-root {
    background: #fff;
    border-left: 4px solid #08652b;
    border-top: 4px solid #08652b;
    border-bottom: 4px solid #08652b;
    font-family: "Georgia", serif;
    font-size: 14px;
    padding: 15px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
}

.RichEditor-editor,
.public-DraftEditor-content,
.RichEditor-root {
    min-height: 100%;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: "Hoefler Text", "Georgia", serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
    font-size: 16px;
    padding: 20px;
}

.RichEditor-controls {
    font-family: "Helvetica", sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
}

.RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.RichEditor-activeButton {
    color: #5890ff;
}

.profile-photo {
    top: 50%;
    left: 51%;
    transform: translateX(-51%);
    box-shadow: 0px 3.52133px 3.52133px rgba(0, 0, 0, 0.25);
}

.pricing_card {
    box-shadow: 0px 3.08152px 7.7038px 1.54076px rgba(0, 0, 0, 0.25);
}