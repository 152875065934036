@import "https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.bottom-\[40px\] {
  bottom: 40px;
}

.left-0 {
  left: 0;
}

.left-\[-21\.33px\] {
  left: -21.33px;
}

.left-\[197px\] {
  left: 197px;
}

.left-\[38px\] {
  left: 38px;
}

.right-0 {
  right: 0;
}

.right-5 {
  right: 1.25rem;
}

.right-\[-38px\] {
  right: -38px;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[152px\] {
  right: 152px;
}

.right-\[192px\] {
  right: 192px;
}

.right-\[24px\] {
  right: 24px;
}

.right-\[30px\] {
  right: 30px;
}

.top-0 {
  top: 0;
}

.top-10 {
  top: 2.5rem;
}

.top-\[100px\] {
  top: 100px;
}

.top-\[190\.49px\] {
  top: 190.49px;
}

.top-\[196\.73px\] {
  top: 196.73px;
}

.top-\[40\%\] {
  top: 40%;
}

.top-\[426\.69px\] {
  top: 426.69px;
}

.top-\[442\.06px\] {
  top: 442.06px;
}

.top-\[46px\] {
  top: 46px;
}

.top-\[582\.75px\] {
  top: 582.75px;
}

.top-\[64px\] {
  top: 64px;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.m-auto {
  margin: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-\[100px\] {
  margin-left: 100px;
  margin-right: 100px;
}

.mx-\[13px\] {
  margin-left: 13px;
  margin-right: 13px;
}

.mx-\[150px\] {
  margin-left: 150px;
  margin-right: 150px;
}

.mx-\[179px\] {
  margin-left: 179px;
  margin-right: 179px;
}

.mx-\[20px\] {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-\[25\%\] {
  margin-left: 25%;
  margin-right: 25%;
}

.mx-\[26px\] {
  margin-left: 26px;
  margin-right: 26px;
}

.mx-\[30px\] {
  margin-left: 30px;
  margin-right: 30px;
}

.mx-\[5\%\] {
  margin-left: 5%;
  margin-right: 5%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-\[11px\] {
  margin-top: 11px;
  margin-bottom: 11px;
}

.my-\[12px\] {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-\[5px\] {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-\[100px\] {
  margin-bottom: 100px;
}

.mb-\[20px\] {
  margin-bottom: 20px;
}

.mb-\[22px\] {
  margin-bottom: 22px;
}

.mb-\[25px\] {
  margin-bottom: 25px;
}

.mb-\[40px\] {
  margin-bottom: 40px;
}

.mb-\[64px\] {
  margin-bottom: 64px;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-\[100px\] {
  margin-left: 100px;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.ml-\[12px\] {
  margin-left: 12px;
}

.ml-\[14px\] {
  margin-left: 14px;
}

.ml-\[15px\] {
  margin-left: 15px;
}

.ml-\[17px\] {
  margin-left: 17px;
}

.ml-\[27px\] {
  margin-left: 27px;
}

.ml-\[2px\] {
  margin-left: 2px;
}

.ml-\[5px\] {
  margin-left: 5px;
}

.ml-\[7px\] {
  margin-left: 7px;
}

.ml-\[96px\] {
  margin-left: 96px;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-\[10px\] {
  margin-right: 10px;
}

.mr-\[15px\] {
  margin-right: 15px;
}

.mr-\[20px\] {
  margin-right: 20px;
}

.mr-\[24px\] {
  margin-right: 24px;
}

.mr-\[25px\] {
  margin-right: 25px;
}

.mr-\[33px\] {
  margin-right: 33px;
}

.mr-\[370px\] {
  margin-right: 370px;
}

.mr-\[47px\] {
  margin-right: 47px;
}

.mr-\[59px\] {
  margin-right: 59px;
}

.mr-\[8px\] {
  margin-right: 8px;
}

.mr-\[9px\] {
  margin-right: 9px;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-\[10px\] {
  margin-top: 10px;
}

.mt-\[134px\] {
  margin-top: 134px;
}

.mt-\[14px\] {
  margin-top: 14px;
}

.mt-\[19px\] {
  margin-top: 19px;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.mt-\[24px\] {
  margin-top: 24px;
}

.mt-\[25px\] {
  margin-top: 25px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[32px\] {
  margin-top: 32px;
}

.mt-\[350px\] {
  margin-top: 350px;
}

.mt-\[35px\] {
  margin-top: 35px;
}

.mt-\[39px\] {
  margin-top: 39px;
}

.mt-\[44px\] {
  margin-top: 44px;
}

.mt-\[47px\] {
  margin-top: 47px;
}

.mt-\[52px\] {
  margin-top: 52px;
}

.mt-\[57px\] {
  margin-top: 57px;
}

.mt-\[65px\] {
  margin-top: 65px;
}

.mt-\[71px\] {
  margin-top: 71px;
}

.mt-\[82px\] {
  margin-top: 82px;
}

.mt-\[9\%\] {
  margin-top: 9%;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[12px\] {
  height: 12px;
}

.h-\[16\.64px\] {
  height: 16.64px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[220px\] {
  height: 220px;
}

.h-\[26px\] {
  height: 26px;
}

.h-\[29px\] {
  height: 29px;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[30\.54px\] {
  height: 30.54px;
}

.h-\[31\.82px\] {
  height: 31.82px;
}

.h-\[31px\] {
  height: 31px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[34px\] {
  height: 34px;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[37\.39px\] {
  height: 37.39px;
}

.h-\[38px\] {
  height: 38px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[41\.2vh\] {
  height: 41.2vh;
}

.h-\[41px\] {
  height: 41px;
}

.h-\[44px\] {
  height: 44px;
}

.h-\[450px\] {
  height: 450px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[49px\] {
  height: 49px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[50vh\] {
  height: 50vh;
}

.h-\[530px\] {
  height: 530px;
}

.h-\[73px\] {
  height: 73px;
}

.h-full {
  height: 100%;
}

.w-\[127px\] {
  width: 127px;
}

.w-\[12px\] {
  width: 12px;
}

.w-\[13px\] {
  width: 13px;
}

.w-\[140px\] {
  width: 140px;
}

.w-\[14x\] {
  width: 14dppx;
}

.w-\[16\.64px\] {
  width: 16.64px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[178px\] {
  width: 178px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[26px\] {
  width: 26px;
}

.w-\[30\.54px\] {
  width: 30.54px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[31\.82px\] {
  width: 31.82px;
}

.w-\[31px\] {
  width: 31px;
}

.w-\[36px\] {
  width: 36px;
}

.w-\[38\.04px\] {
  width: 38.04px;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[41px\] {
  width: 41px;
}

.w-\[49px\] {
  width: 49px;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[500px\] {
  width: 500px;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[700px\] {
  width: 700px;
}

.w-\[85\%\] {
  width: 85%;
}

.w-\[900px\] {
  width: 900px;
}

.w-\[99px\] {
  width: 99px;
}

.w-full {
  width: 100%;
}

.max-w-\[1000px\] {
  max-width: 1000px;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-120\.97deg\] {
  --tw-rotate: -120.97deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-30\.2deg\] {
  --tw-rotate: -30.2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-77\.5deg\] {
  --tw-rotate: -77.5deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[110\.14deg\] {
  --tw-rotate: 110.14deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.03\] {
  --tw-scale-x: 1.03;
  --tw-scale-y: 1.03;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.place-content-center {
  place-content: center;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-\[37px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(37px * var(--tw-space-x-reverse));
  margin-left: calc(37px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[15px\] {
  border-radius: 15px;
}

.rounded-\[16px\] {
  border-radius: 16px;
}

.rounded-\[17px\] {
  border-radius: 17px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[21px\] {
  border-radius: 21px;
}

.rounded-\[24px\] {
  border-radius: 24px;
}

.rounded-\[25px\] {
  border-radius: 25px;
}

.rounded-\[6px\] {
  border-radius: 6px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.rounded-\[9px\] {
  border-radius: 9px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-t-\[10px\] {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-8 {
  border-width: 8px;
}

.border-\[10px\] {
  border-width: 10px;
}

.border-\[2\.2434px\] {
  border-width: 2.2434px;
}

.border-\[\#0DB64D\] {
  --tw-border-opacity: 1;
  border-color: rgb(13 182 77 / var(--tw-border-opacity));
}

.border-\[\#243E36\] {
  --tw-border-opacity: 1;
  border-color: rgb(36 62 54 / var(--tw-border-opacity));
}

.border-\[\#252525\] {
  --tw-border-opacity: 1;
  border-color: rgb(37 37 37 / var(--tw-border-opacity));
}

.border-\[\#7CA982\] {
  --tw-border-opacity: 1;
  border-color: rgb(124 169 130 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-\[\#08652B\] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 101 43 / var(--tw-bg-opacity));
}

.bg-\[\#0DB64D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(13 182 77 / var(--tw-bg-opacity));
}

.bg-\[\#1B348D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 52 141 / var(--tw-bg-opacity));
}

.bg-\[\#243E36\] {
  --tw-bg-opacity: 1;
  background-color: rgb(36 62 54 / var(--tw-bg-opacity));
}

.bg-\[\#285238\] {
  --tw-bg-opacity: 1;
  background-color: rgb(40 82 56 / var(--tw-bg-opacity));
}

.bg-\[\#2ADF64\] {
  --tw-bg-opacity: 1;
  background-color: rgb(42 223 100 / var(--tw-bg-opacity));
}

.bg-\[\#304820\] {
  --tw-bg-opacity: 1;
  background-color: rgb(48 72 32 / var(--tw-bg-opacity));
}

.bg-\[\#41d2f7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(65 210 247 / var(--tw-bg-opacity));
}

.bg-\[\#6BD9A9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(107 217 169 / var(--tw-bg-opacity));
}

.bg-\[\#7CA982\] {
  --tw-bg-opacity: 1;
  background-color: rgb(124 169 130 / var(--tw-bg-opacity));
}

.bg-\[\#DFECD6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(223 236 214 / var(--tw-bg-opacity));
}

.bg-\[\#E0EEC6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 238 198 / var(--tw-bg-opacity));
}

.bg-\[\#F1F7ED\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 247 237 / var(--tw-bg-opacity));
}

.bg-\[\#F2E4DB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 228 219 / var(--tw-bg-opacity));
}

.bg-\[\#F4F4F4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}

.bg-\[\#F7F8FD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 248 253 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-4 {
  padding: 1rem;
}

.p-\[20px\] {
  padding: 20px;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.px-\[17px\] {
  padding-left: 17px;
  padding-right: 17px;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[30px\] {
  padding-left: 30px;
  padding-right: 30px;
}

.px-\[31px\] {
  padding-left: 31px;
  padding-right: 31px;
}

.px-\[50px\] {
  padding-left: 50px;
  padding-right: 50px;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-\[13px\] {
  padding-top: 13px;
  padding-bottom: 13px;
}

.py-\[1px\] {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pb-\[100px\] {
  padding-bottom: 100px;
}

.pb-\[30px\] {
  padding-bottom: 30px;
}

.pb-\[38px\] {
  padding-bottom: 38px;
}

.pb-\[3px\] {
  padding-bottom: 3px;
}

.pb-\[60px\] {
  padding-bottom: 60px;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-\[31px\] {
  padding-left: 31px;
}

.pl-\[40px\] {
  padding-left: 40px;
}

.pl-\[50px\] {
  padding-left: 50px;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-\[100px\] {
  padding-top: 100px;
}

.pt-\[15px\] {
  padding-top: 15px;
}

.pt-\[25px\] {
  padding-top: 25px;
}

.pt-\[42px\] {
  padding-top: 42px;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.pt-\[7px\] {
  padding-top: 7px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[17px\] {
  font-size: 17px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[19px\] {
  font-size: 19px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[25px\] {
  font-size: 25px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[35px\] {
  font-size: 35px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[50px\] {
  font-size: 50px;
}

.text-\[55px\] {
  font-size: 55px;
}

.text-\[70px\] {
  font-size: 70px;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-\[400\] {
  font-weight: 400;
}

.font-\[500\] {
  font-weight: 500;
}

.font-\[600\] {
  font-weight: 600;
}

.font-\[700\], .font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-thin {
  font-weight: 100;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-\[\#08652B\] {
  --tw-text-opacity: 1;
  color: rgb(8 101 43 / var(--tw-text-opacity));
}

.text-\[\#0DB64D\] {
  --tw-text-opacity: 1;
  color: rgb(13 182 77 / var(--tw-text-opacity));
}

.text-\[\#1B348D\] {
  --tw-text-opacity: 1;
  color: rgb(27 52 141 / var(--tw-text-opacity));
}

.text-\[\#243E36\] {
  --tw-text-opacity: 1;
  color: rgb(36 62 54 / var(--tw-text-opacity));
}

.text-\[\#2C3D45\] {
  --tw-text-opacity: 1;
  color: rgb(44 61 69 / var(--tw-text-opacity));
}

.text-\[\#2adf64\] {
  --tw-text-opacity: 1;
  color: rgb(42 223 100 / var(--tw-text-opacity));
}

.text-\[\#4E4E4E\] {
  --tw-text-opacity: 1;
  color: rgb(78 78 78 / var(--tw-text-opacity));
}

.text-\[\#F1F7ED\] {
  --tw-text-opacity: 1;
  color: rgb(241 247 237 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-darkgreen {
  --tw-text-opacity: 1;
  color: rgb(36 62 54 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-\[1px\] {
  --tw-backdrop-blur: blur(1px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.cabin {
  font-family: Cabin, sans-serif;
}

.nav {
  height: calc(100% - 66px);
}

.cabin-600 {
  font-weight: 600;
}

.cabin-700 {
  font-weight: 700;
}

.navLogoPanda {
  width: 30px;
}

.mobilefooterCircle {
  clip-path: ellipse(90% 90% at bottom);
}

.desktopfooterCircle {
  clip-path: ellipse(50vw 100% at bottom);
}

.form {
  left: 50%;
  transform: translateX(-50%);
}

.email_input {
  background-image: url("user1.91149aac.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
}

.password_input {
  background-image: url("lock1.7f3a8a88.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
}

.services {
  box-shadow: 0 4px 4px #00000040;
}

.services:hover {
  -webkit-backdrop-filter: blur(69.1475px);
  backdrop-filter: blur(69.1475px);
  box-shadow: 0 4.32172px 4.32172px #00000040, inset 0 4.32172px 4.32172px #00000040;
}

.headline {
  box-shadow: 0 4px 4px #00000040, inset 0 2.16086px 4.32172px #00000040;
}

.playground-dummy__button {
  box-shadow: inset 0 4px 4px #00000040;
}

.grid-1, .grid-2 {
  box-shadow: 0 1px 10px 3px #00000040;
}

.RichEditor-root {
  background: #fff;
  border-top: 4px solid #08652b;
  border-bottom: 4px solid #08652b;
  border-left: 4px solid #08652b;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 15px;
  font-family: Georgia, serif;
  font-size: 14px;
}

.RichEditor-editor {
  cursor: text;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  font-size: 16px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root, .RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor, .public-DraftEditor-content, .RichEditor-root {
  min-height: 100%;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  color: #666;
  border-left: 5px solid #eee;
  margin: 16px 0;
  padding: 10px 20px;
  font-family: Hoefler Text, Georgia, serif;
  font-style: italic;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: #0000000d;
  padding: 20px;
  font-family: Inconsolata, Menlo, Consolas, monospace;
  font-size: 16px;
}

.RichEditor-controls {
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 5px;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.profile-photo {
  top: 50%;
  left: 51%;
  transform: translateX(-51%);
  box-shadow: 0 3.52133px 3.52133px #00000040;
}

.pricing_card {
  box-shadow: 0 3.08152px 7.7038px 1.54076px #00000040;
}

.marker\:text-\[\#304820\] ::marker {
  color: #304820;
}

.marker\:text-\[\#304820\]::marker {
  color: #304820;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-\[\#08652B\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(8 101 43 / var(--tw-border-opacity));
}

.hover\:bg-\[\#0DB64D\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(13 182 77 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#F7F8FD\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 248 253 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#bef264\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.hover\:font-black:hover {
  font-weight: 900;
}

.hover\:text-\[\#08652B\]:hover {
  --tw-text-opacity: 1;
  color: rgb(8 101 43 / var(--tw-text-opacity));
}

.hover\:text-\[\#0f172a\]:hover {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.focus\:border-\[\#08652B\]:focus {
  --tw-border-opacity: 1;
  border-color: rgb(8 101 43 / var(--tw-border-opacity));
}

.focus\:font-black:focus {
  font-weight: 900;
}

.focus\:text-\[\#0f172a\]:focus {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
}

@media (width >= 768px) {
  .md\:left-\[260px\] {
    left: 260px;
  }

  .md\:left-\[58px\] {
    left: 58px;
  }

  .md\:right-\[260px\] {
    right: 260px;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:ml-\[25px\] {
    margin-left: 25px;
  }

  .md\:mr-\[34px\] {
    margin-right: 34px;
  }

  .md\:mt-\[18px\] {
    margin-top: 18px;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[32px\] {
    height: 32px;
  }

  .md\:w-\[50px\] {
    width: 50px;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }
}

@media (width >= 1024px) {
  .lg\:mx-\[300px\] {
    margin-left: 300px;
    margin-right: 300px;
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  z-index: 1;
  background-color: #fff0;
  border-left: .1px solid #0000;
  position: relative;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  text-align: left;
  left: 0;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  text-align: right;
  right: 0;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  width: 100%;
  z-index: 1;
  position: absolute;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  white-space: pre-wrap;
  position: relative;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  text-align: right;
  width: 30px;
  position: absolute;
  left: -36px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  text-align: left;
  width: 30px;
  position: absolute;
  right: -36px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1, lower-alpha) ". ";
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2, lower-roman) ". ";
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4, lower-alpha) ". ";
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

.loader {
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show {
  display: block;
}

.loader:before {
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-radial-gradient(#141414cc, #000c);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.loader:not(:required) {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.loader:not(:required):after {
  content: "";
  width: 1em;
  height: 1em;
  -ms-animation: loader .15s infinite linear;
  -webkit-box-shadow: 1.5em 0 #243e36bf, 1.1em 1.1em #243e36c6, 0 1.5em #243e36bf, -1.1em 1.1em #243e36bf, -1.5em 0 #243e36bf, -1.1em -1.1em #243e36bf, 0 -1.5em #243e36bf, 1.1em -1.1em #243e36bf;
  border-radius: .5em;
  margin-top: -.5em;
  font-size: 10px;
  animation: .15s linear infinite loader;
  display: block;
  box-shadow: 1.5em 0 #243e36bf, 1.1em 1.1em #243e36bf, 0 1.5em #243e36bf, -1.1em 1.1em #243e36bf, -1.5em 0 #243e36bf, -1.1em -1.1em #243e36bf, 0 -1.5em #243e36bf, 1.1em -1.1em #243e36bf;
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ErNv0W_backdrop {
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: #000000bf;
  position: fixed;
  top: 0;
  left: 0;
}

.ErNv0W_modal {
  width: 90%;
  z-index: 30;
  background-color: #fff;
  border-radius: 14px;
  padding: 1rem;
  animation: .3s ease-out forwards ErNv0W_slide-down;
  position: fixed;
  top: 20vh;
  left: 5%;
  box-shadow: 0 2px 8px #00000040;
}

@media (width >= 768px) {
  .ErNv0W_modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes ErNv0W_slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset .5s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 20px;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: #0000;
}

/*# sourceMappingURL=index.da5b25d1.css.map */
